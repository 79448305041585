import React from 'react';
import Modal from 'react-modal';
import '../styles/components/FeedbackModal.scss';
import propTypes from 'prop-types';

const FeedbackModal = ({
  openModal, closeModal, modalText, modalImg, modalButtonColour,
}) => (
  <Modal className="feedback-modal" isOpen={openModal} onRequestClose={closeModal} ariaHideApp={false}>
    <div className="feedback-modal-div">
      <img className="feedback-modal-img" alt="feedback-img" src={modalImg} />
      <p className="feedback-modal-text">{modalText}</p>
      <button
        type="button"
        className="feedback-modal-button"
        onClick={closeModal}
        style={{ background: modalButtonColour }}
      >
        OK
      </button>
    </div>
  </Modal>
);

FeedbackModal.propTypes = {
  openModal: propTypes.bool.isRequired,
  closeModal: propTypes.func.isRequired,
  modalText: propTypes.string.isRequired,
  modalImg: propTypes.string.isRequired,
  modalButtonColour: propTypes.string.isRequired,
};

export default FeedbackModal;
