import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './routers/AppRouter';
import MainPage from './components/pages/MainPage';

import 'normalize.css/normalize.css';
import './styles/styles.scss';

/**
 * id value of div element
 */
const idChatDiv = 'lab-webchat-div';

if (!document.getElementById(idChatDiv)) {
  const element = document.createElement('div');
  element.id = idChatDiv;
  document.body.appendChild(element);
}
/**
 * gets the root div HTML Element
 */
const root = document.getElementById(idChatDiv);
/**
 * gets the script from the root div element
 */
const script = document.currentScript || document.getElementById('lab-webchat');

// When authentication is used (grab apikey and token and pass it to app)
/** Api key from the script */
const apiKey = script.getAttribute('apiKey');
/** Token value from the script */
const token = script.getAttribute('token');

if (root && apiKey && token) {
  ReactDOM.render(
    <MainPage
      token={token}
      apiKey={apiKey}
    />,
    root,
  );
} else {
  ReactDOM.render(
    <AppRouter />,
    root,
  );
}
