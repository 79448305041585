/**
 * Sets userId in local storage
 * @param {String} userId the userId
 * @returns {null} doesn't return anything
*/
export function setUserId(userId) {
  localStorage.setItem('userId', userId);
}

/**
 * Gets userId in local storage
 *  @return {String} which is the userId
*/
export function getUserId() {
  return localStorage.getItem('userId');
}
