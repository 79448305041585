export default {
  tenant: window.AZURE_ISSUER_TENANT_ID,
  clientId: window.AZURE_CLIENT_ID,
  redirectUri: window.AZURE_REDIRECT_URL,
  navigateToLoginRequestUrl: false,
  endpoints: {
    api: window.AZURE_CLIENT_ID,
  },
  cacheLocation: 'localStorage',
};
