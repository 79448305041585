import React from 'react';
import { Button } from 'kdc-component-library';
import InfoModal from './InfoModal';
import '../styles/components/Banner.scss';

const Banner = () => {
  const [modalOpen, setModalOpen] = React.useState(false);
  return (
    <div className="banner">
      <Button id="info-button" colour="primary" text="Virtual Assistant Info" onClick={() => { setModalOpen(true); }} />
      <InfoModal modalOpen={modalOpen} closeModal={() => { setModalOpen(false); }} />
    </div>
  );
};

export default Banner;
