import React from 'react';
import ChatIcon from '../../assets/ChatIcon.png';

import '../../styles/components/pages/Main.scss';

/**
 * Main page containing the chat button for when the button is placed on Life Pages
 * @returns {*} below JSX
 */
const MainPage = () => {
  let handleWindow = null;

  const buttonClicked = () => {
    if (!handleWindow) {
      handleWindow = window.open(window.AZURE_REDIRECT_URL, 'ChatWindow');
    } else {
      try {
        if (handleWindow.closed) {
          throw new Error();
        }
        handleWindow.focus();
      } catch (error) {
        handleWindow = window.open(window.AZURE_REDIRECT_URL, 'ChatWindow');
      }
    }
  };

  return (
    <div onClick={buttonClicked} onKeyUp={() => {}} role="button" tabIndex={0}>
      <img className="chat-icon" src={ChatIcon} alt="Chat Icon" />
    </div>
  );
};

export default MainPage;
