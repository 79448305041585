import React from 'react';
import '../styles/components/AssistantInfo.scss';
import propTypes from 'prop-types';
import { Dialog } from 'kdc-component-library';
import axios from '../services/Api';
import AuthTypes from '../enums/AuthTypes';
import EditInfoModal from './EditInfoModal';

const AssistantInfo = ({ authorized, modalOpen, closeModal }) => {
  const [mainText, setMainText] = React.useState('');
  const [newText, setNewText] = React.useState('');
  const [wasSuccessful, setWasSuccessful] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(false);

  React.useEffect(() => {
    async function fetchInfo() {
      let res = null;
      try {
        res = await axios.get('/assistant-info');
      } catch (err) {
        return;
      }
      if (res !== null && res.status === 200) {
        setMainText(res.data.mainInfo);
        setNewText(res.data.newInfo);
      }
    }
    fetchInfo();
  }, []);

  const updateInfo = async (e) => {
    e.preventDefault();
    e.persist();
    const newInfo = e.target.elements.newtextarea.value;
    const mainInfo = e.target.elements.maintextarea.value;
    setEditModalOpen(false);
    let res = null;
    try {
      const { data: { csrfToken } } = await axios.get('/csrfToken', {
        withCredentials: true,
      });
      res = await axios.post('/assistant-info', {
        mainInfo,
        newInfo,
      }, {
        headers: {
          'X-XSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (err) {
      setWasSuccessful('Assistant info post unsuccessful');
      setOpenDialog(true);
    }
    if (res !== null && res.status === 200) {
      setWasSuccessful('Assistant info posted successfully');
      setOpenDialog(true);
      try {
        res = await axios.get('/assistant-info');
      } catch (err) {
        return;
      }
      if (res !== null && res.status === 200) {
        setMainText(res.data.mainInfo);
        setNewText(res.data.newInfo);
      }
    } else if (res !== null) {
      setWasSuccessful('Assistant info post unsuccessful');
      setOpenDialog(true);
    }
  };

  return (
    <div className="entire-container">
      <p className="info-title">Virtual Assistant - About Me</p>
      {authorized === AuthTypes.Admin && !modalOpen
        && (
          <button id="edit-button" className="edit-button" type="button" onClick={() => { setEditModalOpen(true); }}>Edit</button>
        )}
      {modalOpen ? (<button className="close-modal-button" id="close-modal-button" type="button" onClick={closeModal}>X</button>) : <div />}
      <div className="info-container">
        <p className="info-text">
          <pre className="pre">{mainText}</pre>
        </p>
        {newText !== ''
          && (
            <div>
              <p className="info-news">NEWS</p>
              <p className="news-text">
                <pre className="pre">{newText}</pre>
              </p>
            </div>
          )}
      </div>
      <Dialog
        open={openDialog}
        onClose={() => { setOpenDialog(false); }}
        function={null}
        title="Update Assistant Info"
        content={wasSuccessful}
      />
      <EditInfoModal
        updateInfo={updateInfo}
        mainContent={mainText}
        newContent={newText}
        editModalOpen={editModalOpen}
        closeEditModal={() => { setEditModalOpen(false); }}
      />
    </div>
  );
};

AssistantInfo.defaultProps = {
  authorized: AuthTypes.User,
  modalOpen: false,
  closeModal: () => { },
};

AssistantInfo.propTypes = {
  authorized: propTypes.number,
  modalOpen: propTypes.bool,
  closeModal: propTypes.func,
};

export default AssistantInfo;
