import authContext from './AuthService';
import AdalConfig from '../config/AdalConfig';
import AuthTypes from '../enums/AuthTypes';

/** Login Service */
const loginService = () => {
  let authorized = AuthTypes.Unauthorized;
  authContext().handleWindowCallback();
  if (
    window === window.parent
    && window === window.top
    && !authContext().isCallback(window.location.hash)
  ) {
    if (
      !authContext().getCachedToken(AdalConfig.clientId)
      || !authContext().getCachedUser()
    ) {
      authContext().login();
    } else {
      authContext().acquireToken(AdalConfig.endpoints.api, (message, token) => {
        if (token) {
          if (
            authContext()._user.profile.roles && authContext()._user.profile.roles.find(
              (elm) => elm === window.ADMIN_ROLE_STRING,
            )
          ) {
            authorized = AuthTypes.Admin;
          } else if (
            authContext()._user.profile.roles && authContext()._user.profile.roles.find(
              (elm) => elm === window.USER_ROLE_STRING,
            )
          ) {
            authorized = AuthTypes.User;
          } else {
            authorized = AuthTypes.Unauthorized;
          }
        }
      });
    }
  }
  return authorized;
};

export default loginService;
