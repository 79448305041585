import React from 'react';
import propTypes from 'prop-types';
import FeedbackSuccess from '../assets/Feedback-Success.png';
import FeedbackUnsuccessful from '../assets/Feedback-Unsuccessful.png';
import FeedbackModal from './FeedbackModal';
import '../styles/components/Feedback.scss';
import axios from '../services/Api';

/**
 * Feedback component
 * @param {String} userId
 * @returns {JSX} JSX to render the component
 */
const Feedback = ({ userId }) => {
  const [wasHelpful, setWasHelpful] = React.useState(null);
  const [yesNoClick, setYesNoClick] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalText, setModalText] = React.useState('');
  const [modalButtonColour, setModalButtonColour] = React.useState('');
  const [modalImg, setModalImg] = React.useState('');
  const [showMessage, setShowMessage] = React.useState(false);

  const setRadioButton = (id, value) => {
    const element = document.getElementById(id);
    if (element) element.checked = value;
  };

  const setPositive = () => {
    setModalText('Thank you for your feedback!');
    setModalButtonColour('#629c44');
    setModalImg(FeedbackSuccess);
  };

  const setNegative = () => {
    setModalText('Please select either Yes or No.');
    setModalButtonColour('#e61610');
    setModalImg(FeedbackUnsuccessful);
  };

  const submitFeedback = async (e) => {
    e.preventDefault();
    e.persist();
    if (yesNoClick) {
      let res = null;
      try {
        const { data: { csrfToken } } = await axios.get('/csrfToken', {
          withCredentials: true,
        });
        res = await axios.post('/feedback', {
          userId,
          feedback: e.target.elements.feedback.value,
          wasHelpful,
        }, {
          headers: {
            'X-XSRF-TOKEN': csrfToken,
          },
          withCredentials: true,
        });
      } catch {
        setNegative();
        setOpenModal(true);
      }
      if (res !== null && res.status === 200) {
        setPositive();
        setOpenModal(true);
      } else if (res !== null) {
        setNegative();
        setOpenModal(true);
      }
      e.target.elements.feedback.value = '';
      setWasHelpful(null);
      setYesNoClick(null);
      setRadioButton('yes-button', false);
      setRadioButton('no-button', false);
    } else {
      setNegative();
      setOpenModal(true);
    }
  };

  const yesNoButtonClick = (sentiment) => {
    setWasHelpful(sentiment);
    setYesNoClick(true);
    if (sentiment) {
      setRadioButton('yes-button', true);
      setRadioButton('no-button', false);
    } else {
      setRadioButton('yes-button', false);
      setRadioButton('no-button', true);
    }
  };

  return (
    <div className="feedback-div">
      <form onSubmit={submitFeedback} id="feedback-form">
        <div className="container">
          <p className="feedback-text" id="feedback-text">Was this information helpful?</p>
          <div className="radio-input-div">
            <div className="radio-single-div">
              <input
                id="yes-button"
                onClick={() => { yesNoButtonClick(true); }}
                className="feedback-radio"
                type="radio"
              />
              <p className="radio-input-text">Yes</p>
            </div>
            <div className="radio-single-div">
              <input
                id="no-button"
                onClick={() => { yesNoButtonClick(false); }}
                className="feedback-radio"
                type="radio"
              />
              <p className="radio-input-text">No</p>
            </div>
          </div>
        </div>
        <div className="container">
          <input
            className="feedback-input"
            name="feedback"
            type="text"
            placeholder="Type comments here (optional)"
            id="feedback-input"
          />
          <button
            onPointerEnter={() => { if (yesNoClick === null) setShowMessage(true); }}
            onPointerLeave={() => { if (yesNoClick === null) setShowMessage(false); }}
            disabled={yesNoClick === null}
            className="submit-button"
            type="submit"
            id="submit-button"
          >
            Submit Feedback
          </button>
        </div>
        <div className="message-div" hidden={!showMessage}>Please select either Yes or No.</div>
      </form>
      <FeedbackModal
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        modalText={modalText}
        modalImg={modalImg}
        modalButtonColour={modalButtonColour}
      />
    </div>
  );
};

Feedback.defaultProps = {
  userId: null,
};

Feedback.propTypes = {
  userId: propTypes.string,
};

export default Feedback;
