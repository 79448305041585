import React from 'react';
import Modal from 'react-modal';
import propTypes from 'prop-types';
import '../styles/components/EditInfoModal.scss';

const EditInfoModal = ({
  updateInfo, mainContent, newContent, editModalOpen, closeEditModal,
}) => (
  <Modal className="modal" isOpen={editModalOpen} onRequestClose={closeEditModal} ariaHideApp={false}>
    <form id="update-form" onSubmit={updateInfo} className="modal__body">
      <div className="individual-container">
        <div className="text-container"><p className="subtitles">Main Body:</p></div>
        <textarea name="maintextarea" className="update-info">{mainContent}</textarea>
      </div>
      <div className="individual-container">
        <div className="text-container"><p className="subtitles">New Content:</p></div>
        <textarea name="newtextarea" className="update-info">{newContent}</textarea>
      </div>
      <div className="button-div">
        <button className="edit-modal-buttons" type="submit">Submit</button>
        <button onClick={closeEditModal} className="edit-modal-buttons" type="button">Cancel</button>
      </div>
    </form>
  </Modal>
);

EditInfoModal.defaultProps = {
  updateInfo: () => {},
  mainContent: '',
  newContent: '',
  editModalOpen: false,
  closeEditModal: () => {},
};

EditInfoModal.propTypes = {
  updateInfo: propTypes.func,
  mainContent: propTypes.string,
  newContent: propTypes.string,
  editModalOpen: propTypes.bool,
  closeEditModal: propTypes.func,
};

export default EditInfoModal;
