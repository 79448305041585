import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import CacheBuster from '../components/CacheBuster';
import MainPage from '../components/pages/MainPage';
import Chat from '../components/pages/Chat';
import PDF from '../components/PDF';

/**
 * Router wrapped with CacheBuster to refresh and reload
 * @returns {*} JSX
 */
const AppRouter = () => (
  <CacheBuster>
    {({ isLoading, isLatestVersion, refreshCacheAndReload }) => {
      if (isLoading) return null;
      if (!isLoading && !isLatestVersion) {
        refreshCacheAndReload();
      }
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Chat} exact />
            <Route path="/main-page" component={MainPage} exact />
            <Route path="/open-pdf/:doc/:bucket/:search?" component={PDF} exact />
          </Switch>
        </BrowserRouter>
      );
    }}
  </CacheBuster>
);
export default AppRouter;
