import React, { useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import reactStringReplace from 'react-string-replace';
import propTypes from 'prop-types';
import '../styles/components/PDF.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDF = ({ match }) => {
  const { bucket } = match.params;
  const { doc } = match.params;
  const file = `https://us-east.functions.appdomain.cloud/api/v1/web/KDCORG_${bucket}/default/download?id=${doc}`;
  let { search } = match.params;
  // replacing characters for '/' and '.'
  if (search) {
    search = search.replaceAll(/<S>/g, '/').replaceAll(/<D>/g, '.');
  }
  // the current index in the passage in params being compared from
  let strIndex = 0;
  // highest index the string got to
  let maxStrIndex = 0;
  // page to open the document on
  let pageIndex = 0;
  // to give 10 lines before strIndex resets
  let count = 0;
  const [pages, setPages] = React.useState([]);

  async function fetchData() {
    pdfjs.getDocument(file).promise.then((docData) => {
      const temp = [];
      for (let i = 0; i < docData._pdfInfo.numPages; i += 1) temp.push(docData.getPage(i + 1));
      setPages(temp);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  // helper function to find longest common substring
  function longestCommonSubstring(str1, str2) {
    const s1 = [...str1];
    const s2 = [...str2];
    const arr = Array(s2.length + 1).fill(null).map(() => Array(s1.length + 1).fill(null));
    for (let j = 0; j <= s1.length; j += 1) {
      arr[0][j] = 0;
    }
    for (let i = 0; i <= s2.length; i += 1) {
      arr[i][0] = 0;
    }
    let len = 0;
    let col = 0;
    let row = 0;
    for (let i = 1; i <= s2.length; i += 1) {
      for (let j = 1; j <= s1.length; j += 1) {
        if (s1[j - 1] === s2[i - 1]) {
          arr[i][j] = arr[i - 1][j - 1] + 1;
        } else {
          arr[i][j] = 0;
        }
        if (arr[i][j] > len) {
          len = arr[i][j];
          col = j;
          row = i;
        }
      }
    }
    if (len === 0) {
      return '';
    }
    let res = '';
    while (arr[row][col] > 0) {
      res = s1[col - 1] + res;
      row -= 1;
      col -= 1;
    }
    return res;
  }

  const textRenderer = ({ str, page }) => {
    if (search && strIndex !== -1) {
      let compare = search;
      if (strIndex < compare.length) {
        // removing double spaces
        const newStr = str.replace(/  +/g, ' ');
        // getting rid of leading white space because str removes all leading whitespace
        if (compare.charAt(strIndex) === ' ') strIndex += 1;
        // getting string to compare from params
        compare = compare.substring(strIndex, strIndex + newStr.length);
        if (newStr) {
          const lcs = longestCommonSubstring(newStr, compare);
          if (lcs && lcs.length > Math.ceil(newStr.length / 2)) {
            strIndex += lcs.length;
            compare = lcs;
            count = 0;
            if (strIndex > maxStrIndex) {
              maxStrIndex = strIndex;
              pageIndex = page._pageIndex;
            }
          } else {
            count += 1;
            if (count > 10) {
              strIndex = 0;
              count = 0;
            }
            return str;
          }
        } else {
          count += 1;
          if (count > 10) {
            strIndex = 0;
            count = 0;
          }
          return str;
        }
        return (
          reactStringReplace(newStr, compare, (sameText, i) => (
            <mark key={i}>{sameText}</mark>
          ))
        );
      }
      strIndex = -1;
      return str;
    }
    return str;
  };

  return (
    <div>
      <Document
        className="document"
        file={file}
      >
        <a id="download-link" className="download" href={file} download={doc}>Download</a>
        {pages.map((_, index) => (
          <div
            id={`page_${index + 1}`}
            key={`page_${index + 1}`}
            className="page"
          >
            <Page
              pageNumber={index + 1}
              customTextRenderer={textRenderer}
              scale={1.5}
              onRenderSuccess={() => {
                if (index === pages.length - 1) {
                  document.getElementById(`page_${pageIndex + 1}`).scrollIntoView();
                }
              }}
            />
          </div>
        ))}
      </Document>
    </div>
  );
};

PDF.defaultProps = {
  match: {},
};

PDF.propTypes = {
  match: propTypes.shape({
    params: propTypes.objectOf(propTypes.string),
  }),
};

export default PDF;
