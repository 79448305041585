import axios from 'axios';
import AdalConfig from '../config/AdalConfig';
import ApiConfig from '../config/ApiConfig';
import AuthContext from './AuthService';
/**
 *  Creates an axios instance with the baseURL set to the backend,
 *  and potentially more configuration.
 */
const instance = axios.create(ApiConfig);
/** Ensures current authorization token is included with every request */
instance.interceptors.request.use(
  (config) => new Promise((resolve, reject) => {
    AuthContext().acquireToken(
      AdalConfig.endpoints.api,
      // eslint-disable-next-line no-unused-vars
      (message, token, msg) => {
        if (token) {
          const authConfig = {
            ...config,
            headers: {
              ...config.headers,
              authorization: `Bearer ${token}`,
            },
          };
          resolve(authConfig);
        } else {
          reject(config);
        }
      },
    );
  }),
);
export default instance;
