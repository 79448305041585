import AuthenticationContext from 'adal-angular';
import AdalConfig from '../config/AdalConfig';

/**
 * Configures ADAL authentication
 * @return {AuthenticationContext} ADAL authentication object
 */
const authContext = () => new AuthenticationContext(AdalConfig);

export default authContext;
