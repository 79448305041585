/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import '../../styles/components/pages/Chat.scss';
import { DirectLine } from 'botframework-directlinejs';
import ReactWebChat, { createStore, Components } from 'botframework-webchat';
import { v4 as uuidv4 } from 'uuid';
import { Dialog, Logo } from 'kdc-component-library';
import Feedback from '../Feedback';
import AssistantInfo from '../AssistantInfo';
import Banner from '../Banner';
import { getUserId, setUserId } from '../../services/user';
import loginService from '../../services/LoginService';
import authContext from '../../services/AuthService';
import AuthTypes from '../../enums/AuthTypes';
import axios from '../../services/Api';

/**
 * Middleware that gets called on every message
 * @returns {Object} object that is passed to next
 */
const activityMiddleware = () => (next) => (card) => next(card);

/**
 * Wraps the React web chat in a container
 */
export class Chat extends Component {
  /**
   * Constructor to initialize the Directline token
   * @param {*} props properties passed to component
   */
  constructor(props) {
    super(props);

    /**
     * Initialize Directline
     */
    const directlineInit = new DirectLine({});

    /** initialize state */
    this.state = {
      userId: null,
      authorized: AuthTypes.Unauthorized,
      token: null,
      directline: directlineInit,
      hasAttachments: false,
      showLogo: false,
    };
    this.logout = this.logout.bind(this);

    /** Function to handle actions received during the conversation */
    this.actionHandler = (dispatch, next, action) => {
      const { hasAttachments, showLogo } = this.state;
      if (
        action.payload
        && action.payload.activity
        && action.payload.activity.type === 'message'
        && showLogo === true
      ) {
        const sendbox = document.querySelector("input[data-id='webchat-sendbox-input']");
        this.setState({ showLogo: false });
        if (sendbox) {
          sendbox.disabled = false;
        }
      }
      // Send an event on successful connection to receive welcome message from the bot
      if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
        dispatch({
          type: 'WEB_CHAT/SEND_EVENT',
          payload: {
            name: 'webchat/join',
          },
        });
      }

      // Check if the incoming activity has attachments and saves that to the state
      if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY' && action.payload.activity.attachments) {
        this.setState({ hasAttachments: true });
      }

      // Any time user chooses a suggested action we should focus on input after bot message or
      // If there are attachments, sets focus to the most recent bot message
      // so clicks can be registered
      if (action.type === 'WEB_CHAT/SET_SUGGESTED_ACTIONS'
         || (action.type === 'DIRECT_LINE/POST_ACTIVITY_FULFILLED' && hasAttachments)) {
        const lastBotMessage = document.querySelector(
          '.webchat__basic-transcript__activity--from-bot:last-child div.webchat__basic-transcript__activity-sentinel',
        );
        if (lastBotMessage) {
          setTimeout(() => {
            lastBotMessage.focus();
          }, 300);
        }
        this.setState({ hasAttachments: false });
      }
      return next(action);
    };

    /** Creates the webchat store to allow for custom event handling */
    this.store = createStore(
      {},
      ({ dispatch }) => (next) => (action) => this.actionHandler(dispatch, next, action),
    );

    /**
     * Attachment middleware to disable card components after user interaction
     * Found Here: https://github.com/microsoft/BotFramework-WebChat/issues/1427
     * and full example of use in a webchat bot here:
     * https://github.com/microsoft/BotFramework-WebChat/blob/master/samples/05.custom-components/l.disable-adaptive-cards/index.html
     */
    // eslint-disable-next-line react/display-name
    this.attachmentMiddleware = () => (next) => ({ activity, attachment, ...others }) => {
      // eslint-disable-next-line react/no-this-in-sfc
      const { activities } = this.store.getState();
      const messageActivities = activities.filter((botActivity) => botActivity.type === 'message');
      const recentBotMessage = messageActivities.pop() === activity;

      switch (attachment.contentType) {
        case 'application/vnd.microsoft.card.adaptive':
          return (
            <Components.AdaptiveCardContent
              content={attachment.content}
              disabled={!recentBotMessage}
            />
          );

        case 'application/vnd.microsoft.card.animation':
          return (
            <Components.AnimationCardContent
              content={attachment.content}
              disabled={!recentBotMessage}
            />
          );

        case 'application/vnd.microsoft.card.audio':
          return (
            <Components.AudioCardContent
              content={attachment.content}
              disabled={!recentBotMessage}
            />
          );

        case 'application/vnd.microsoft.card.hero':
          return (
            <Components.HeroCardContent
              content={attachment.content}
              disabled={!recentBotMessage}
            />
          );

        case 'application/vnd.microsoft.card.oauth':
          return (
            <Components.OAuthCardContent
              content={attachment.content}
              disabled={!recentBotMessage}
            />
          );

        case 'application/vnd.microsoft.card.receipt':
          return (
            <Components.ReceiptCardContent
              content={attachment.content}
              disabled={!recentBotMessage}
            />
          );

        case 'application/vnd.microsoft.card.signin':
          return (
            <Components.SignInCardContent
              content={attachment.content}
              disabled={!recentBotMessage}
            />
          );

        case 'application/vnd.microsoft.card.thumbnail':
          return (
            <Components.ThumbnailCardContent
              content={attachment.content}
              disabled={!recentBotMessage}
            />
          );

        case 'application/vnd.microsoft.card.video':
          return (
            <Components.VideoCardContent
              content={attachment.content}
              disabled={!recentBotMessage}
            />
          );

        default:
          return next({ activity, attachment, ...others });
      }
    };
  }

  /**
   * React LifeCycle method for App
   * @return {null} returns nothing
   * */
  async componentDidMount() {
    const authorized = loginService();
    const token = localStorage.getItem('adal.idtoken');
    // Every time the user starts a new session, track the login
    const userLoginTracked = sessionStorage.getItem('userLoginTracked');
    if (!userLoginTracked) {
      sessionStorage.setItem('userLoginTracked', 'true');
      await axios.post('/login');
    }

    let userId = getUserId();
    if (!userId) {
      // Generate a new user id for this user if they haven't got one initialized
      userId = uuidv4();
      setUserId(userId);
    }
    this.setState({ userId, authorized, token });

    // Removes scroll on LAB page
    if (window.location.origin === window.AZURE_REDIRECT_URL) {
      document.body.style.overflow = 'hidden';
    }

    await this.initializeDirectline(userId);

    const sendbox = document.querySelector("input[data-id='webchat-sendbox-input']");
    this.setState({ showLogo: true });
    if (sendbox) {
      sendbox.disabled = true;
    }
  }

  /**
   * Performs directline initialization by generating a token for use with DirectLine
   * @param {*} userId the ID of the current user
   * @return {null} returns nothing
   */
  async initializeDirectline(userId) {
    try {
      const { data } = await axios.get(`/token?userId=${userId}`);
      const { token } = data;
      /**
       * sets the directline token from the direct line service
       */
      const directline = new DirectLine({ token });
      this.setState({ directline });
    } catch (err) {
      // catch any error that may occur from aceessing the data from the axios call
    }
  }

  /**
   * Logs the user out of their session and resets
   * the state variables
   * @return {null} returns nothing
   */
  logout() {
    this.setState({ authorized: AuthTypes.Unauthorized, token: null });
    authContext().logOut();
  }

  /**
   * Renders the actual JSX
   * @returns {JSX} component
   */
  render() {
    const {
      userId, authorized, token, directline, showLogo,
    } = this.state;

    return (
      <>
        {authorized === AuthTypes.User || authorized === AuthTypes.Admin
          ? (
            <div>
              <div className="react-webchat-container">
                <Banner />
                {showLogo && (
                  <div className="logo-div">
                    <Logo type="KDCSpinningLogo" />
                  </div>
                )}
                {
                userId && (
                  <ReactWebChat
                    userID={userId}
                    className="react-web-chat"
                    activityMiddleware={activityMiddleware}
                    attachmentMiddleware={this.attachmentMiddleware}
                    directLine={directline}
                    store={this.store}
                    styleOptions={{
                      botAvatarInitials: 'C',
                      bubbleBorderRadius: 8,
                      bubbleFromUserBorderRadius: 8,
                      bubbleBackground: '#f2f2f2',
                      bubbleBorderColor: '#f2f2f2',
                      bubbleFromUserBackground: '#deeaf6',
                      bubbleFromUserBorderColor: '#deeaf6',
                      hideUploadButton: true,
                      sendBoxButtonColor: 'white',
                      sendBoxButtonColorOnHover: 'white',
                    }}
                  />
                )
              }
                <Feedback userId={userId} />
              </div>
              <div className="info-div">
                <AssistantInfo authorized={authorized} modalOpen={false} />
              </div>
            </div>
          )
          : token && (
          <Dialog
            open
            onClose={this.logout}
            function={null}
            title="Error, Access Denied."
            content="The user you have logged in with does not have permission to use this site."
          />
          )}
      </>
    );
  }
}

export default Chat;
